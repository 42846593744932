.cooperationSection {
  min-height: 60vh; /* Jeder Abschnitt nimmt die volle Bildschirmhöhe ein */
  width: 100%; /* Die volle Bildschirmbreite */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white; /* Hintergrundfarbe anpassen */
  color: black;
  
}
.abtrennungsLinie {
  border: 1px solid black; /* Schwarze Linie mit 1px Breite und solidem Stil */
  width: 98%;
  margin: 0px 0px 3rem 0px; /* Optional: Abstand oberhalb und unterhalb der Linie */
}
.cooperationHeader{
  margin-top: 3rem;
  margin-bottom: 5rem;

}
.cooperation-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.cooperation-image{
  max-height: 8rem;
  max-width: 12rem;
  padding: 2rem 10rem;
}

@media (max-width: 1550px) {
  .cooperation-image{
    max-height: 8rem;
    padding: 3rem 5rem;
  }
}


@media (max-width: 768px) {
  .cooperation-image{
    max-height: 8rem;
    padding: 2rem 3rem;
  }
  .cooperationHeader{
    margin-top: 3rem;
    margin-bottom: 4rem;
  
  }
}

