/* Section2.css */

/* Center-align the parent container */
.image-rows {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
  gap: 7rem; /* Add spacing between cards */
  padding: 20px; /* Add padding to the container */
  max-width: 100%; /* Ensure the container doesn't exceed the viewport width */
  font-size: 20px;

}

.quote {
  font-style: italic;
  font-size: 19px;
  /* Weitere CSS-Eigenschaften für Zitate nach Bedarf hinzufügen */
}


/* Adjust card styles for responsiveness */
.image-card {
  text-align: center;
  padding: 2rem;
  padding-bottom: 0rem;
  min-width: 200px;
  max-width: 30%; /* Set a maximum width for each image card */
  margin: 0 5%; /* Adjust the margin to control the spacing between cards */
}

/* Make images circular */
.round-image {
  width: 100%; /* Allow the image to take the full width of the card */
  max-width: 350px; /* Set a maximum width for the image */
  object-fit: cover;
  max-height: 350px; /* Automatically adjust the height to maintain aspect ratio */
  border-radius: 50%; /* Create a circular shape by setting border-radius to 50% */
}

/* Center-align header text */
.image-header {
  margin-top: 10px;
  text-align: center; /* Center-align header text */
}

/* Adjust description text */
.image-description {
  margin-top: 5px;
  white-space: break-spaces;
  text-align: center; /* Center-align description text */
}

.Header {
  padding: 7rem 10% 3rem 10%; /* Adjust padding for smaller screens */
  text-align: center; /* Center-align the header text on all screens */
}

@media screen and (max-width: 800px) {

  .image-card {
    text-align: center;
    padding: 2rem;
    padding-bottom: 0rem;
    min-width: 200px;
    max-width: 60%; /* Set a maximum width for each image card */
    margin: 0 5%; /* Adjust the margin to control the spacing between cards */
  }

}