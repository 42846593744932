.header-image-physics-based-ui {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 40%, rgba(0,0,0, 1)), url("/public/UI_Image_Top.png"); /* Passe den Pfad zum Header-Bild an */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7; /* Die Opazität (Deckkraft) des Header-Bilds */
    height: 40rem;
  }

  /* Media query for small screens */
@media (max-width: 768px) {
    .header-image-physics-based-ui {
      background-image: url('/public/UI_Image_Top.png'); /* Replace with the path to your small screen image */
    }
  }