@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
}

.container-s6 {
  width: 100%;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 3rem;
  height: 100%; /* Setzen Sie die Höhe des Container-S5 auf 100% */
}


.section-title-s6 {
  font-size: 2rem; /* Größere Schrift für den Titel */
  color: #333; /* Dunklere Textfarbe */
  margin-bottom: 2rem; /* Unten etwas Platz für den Titel */

}
.row-section6 {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  margin-bottom: 2rem;
  margin: 0 3rem;
}

.column-section6 {
  flex: 0.6 0.4;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: start;
}

.column-section6 img {
  margin-top: 20px;
  aspect-ratio:   833 / 450;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(10,10,10,.4);
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  object-fit: cover;

}

.Text-section6
{
  font-size: 1.1rem;
  line-height: 1.5rem;
}





@media screen and (max-width: 1200px) {

  .column-section6 {
    flex: 1;
  
    padding: 3rem 4rem;
    text-align: start;
    justify-content: center;
  }

  .Text-section6
{
  font-size: 0.8rem;
  line-height: 1.5rem;
}
}

@media screen and (max-width: 1000px) {

  .Text-section6
{
  font-size: 0.7rem;
  line-height: 1.5rem;
}
  .column-section6 {
    flex: 1;
  
    padding: 3rem 2rem;
    text-align: start;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .row-section6 {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-bottom: 2rem;
    margin: 2rem 4rem;
  }
  .section-title-s6{
    font-size: 6vw;
  }
  .column-section6 {
    flex: 1;
  
    padding:0rem;
    text-align: start;
    justify-content: center;
  }


  .Text-section6
{
  padding: 2rem 0;
  font-size: 0.8rem;
  line-height: 1.3rem;
}

  .column-section6 img {
    margin-top: 20px;
    aspect-ratio:   833 / 450;
    border-radius: 16px;
    box-shadow: 0 4px 4px rgba(10,10,10,.4);
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;

  
  }
}


