.Main {
  text-align: center;
  min-height: 100vh;

}

.Main-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Main-logo {
    animation: Main-logo-spin infinite 20s linear;
  }
}

.Main-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.Main-link {
  color: #61dafb;
}

@keyframes Main-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

/* Style for the link within the footer */
.imprint-link {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin-left: 10px; /* Add some spacing between the text and the link */
}
/* Style for the link on hover */
.imprint-link:hover {
  text-decoration: underline;
}
