

/* Stile für das Symbol */
.icon {
  height: 5rem;
  display: flex;
  justify-content: center; /* Horizontal zentrieren */
  align-items: center; /* Vertikal zentrieren */
  margin-bottom: 1.5rem;
}

.imgtext {
  width: 70%;
}
.phrase {
  text-align: justify;
  line-height: 150%;
  font-size: 1.2rem;

}

.section-title {
  padding: 2rem 20%; /* Adjust padding for smaller screens */
  font-size: 20px;

  /* Andere Stile hier einfügen */
}

@media screen and (max-width: 600px) {
.phrase {
  text-align:left;
  font-size: 3vw;
  line-height: 200%;
  padding: 1rem 10%;
}

}
.icon img {
  width: 15%;
  height: auto;
  /* Andere Stile hier einfügen */
}

/* Stile für den Abschnittstitel */



@media screen and (max-width: 1200px) {
  .imgtext {
    width: 90%;
  }
  .section-title {

    padding: 2rem 12%; /* Adjust padding for smaller screens */
    padding-top: 5rem;
    font-size: 30px;
  
    /* Andere Stile hier einfügen */
  }
}
/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  .text-passage {
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  .icon {
    height: 3rem; /* Reduce icon size for smaller screens */
    margin-bottom: 1rem;
  }

  /* Adjust grid layout for smaller screens */
  .text-passage-container {
    grid-template-columns: 1fr; /* Display one column on smaller screens */
    margin-left: 5%; /* Adjust margin for smaller screens */
    margin-right: 5%; /* Adjust margin for smaller screens */
  }

  .section-title {
    font-size: 4vw;
    padding: 2rem 3%; /* Adjust padding for smaller screens */
  }

  
}