.header-image-CNC {
    
  background-image: 
  linear-gradient(to top, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0) 40%),
  linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0) 40%),
  linear-gradient(to left, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0) 40%),
  linear-gradient(to right, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0) 40%),
  url("/public/CNC_1.jpg");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7; /* Die Opazität (Deckkraft) des Header-Bilds */
    height: 40rem;
  }

  /* Media query for small screens */
@media (max-width: 768px) {
    .header-image {
      background-image: url('/public/CNC_1.jpg'); /* Replace with the path to your small screen image */
    }
  }