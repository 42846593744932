.header-image-Memoria {
    background-image: url("/public/Memoria.jpg"); /* Passe den Pfad zum Header-Bild an */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7; /* Die Opazität (Deckkraft) des Header-Bilds */
    height: 40rem;
  }

  /* Media query for small screens */
@media (max-width: 768px) {
    .header-image-Memoria {
      background-image: url('/public/Memoria_Small.jpg'); /* Replace with the path to your small screen image */
    }
  }